import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from "@reduxjs/toolkit";
import InstagramPost from "../../types/InstagramPost";

export const initialState = {
    latestPost: null as InstagramPost | null,
}

export const instagramSlice = createSlice({
    name: 'instagram',
    initialState,
    reducers: {
        setLatestPost: (state, action: PayloadAction<InstagramPost>) => {
            state.latestPost = action.payload;
        }
    },
});

export const { setLatestPost } = instagramSlice.actions;

export default instagramSlice.reducer;
