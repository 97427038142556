import React from "react";
import Layout from "../layout/Layout";
import Chat from "../components/Chat";
import MyCard from "../components/MyCard";
import {Grid} from "@mui/material";
// import SignUp from "../components/SignUp";
import DynamicHelmet from "../components/DynamicHelmet";
import InstagramPost from "../components/InstagramPost";
import StravaActivity from "../components/StravaActivity";

const MainPage = () => {
    return (<Layout>
        <DynamicHelmet title="Vitalii Pavliuk website" description="Vitalii Pavliuk website" imageUrl="https://vitalii.tech/vitalii.png" url="https://vitalii.tech"/>
        <Grid container sx={{height: "100vh", overflowY: "auto", backgroundColor: "#EEE"}}>
            <Grid item xs={12} md={6}>
                <MyCard />
                <StravaActivity />
                <InstagramPost />
            </Grid>
            <Grid item xs={12} md={6}>
                <Chat chatType="vitalii" avatarSrc="/twin.jpg" title="My Digital Twin" subheader="Online" sliceSelector={(state) => state.gpt} />
                {/* <SignUp /> */}
            </Grid>
        </Grid>
    </Layout>);
}

export default MainPage;