import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from "@reduxjs/toolkit";

export interface ChatMessage {
    role: "user" | "assistant";
    content: string;
}

export interface GPTState {
  messages: Array<ChatMessage>;
  isLoading: boolean;
}

export const initialState: GPTState = {
    messages: [],
    isLoading: false,
}

export const zlativGPTSlice = createSlice({
  name: 'gpt',
  initialState,
  reducers: {
    setMessages: (state, action: PayloadAction<Array<ChatMessage>>) => {
        state.messages = action.payload;
    },
      setIsLoading: (state, action: PayloadAction<boolean>) => {
        state.isLoading = action.payload;
      },
      appendMessage: (state, action: PayloadAction<ChatMessage>) => {
          state.messages.push(action.payload);
      }
  },
});

export const { setMessages, setIsLoading, appendMessage } = zlativGPTSlice.actions;

export default zlativGPTSlice.reducer;
