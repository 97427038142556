import React from "react";
import {Avatar, Button, Card, CardActions, CardContent, CardHeader, IconButton, Typography} from "@mui/material";
import {Instagram, LinkedIn, Telegram} from "@mui/icons-material";
import {useTranslation} from "react-i18next";

const MyCard = () => {
    const { t } = useTranslation();

    return <Card sx={{margin: { xs: 1, md: 10}}}>
        <CardHeader
            avatar={
            <Avatar src="/vitalii.png" />
        }
            title="Vitalii Pavliuk"

        />
        <CardContent>
            <Typography variant="body1" color="text.primary">
                {t("Hey! I'm Vitalii Pavliuk, a tech enthusiast and programming mentor from Ukraine. Dive into the digital realm with my AI-powered twin for a unique tech conversation, or book a session to elevate your coding skills with personalized guidance. Join me at the intersection of technology and education, where every interaction is a step towards mastering the digital world.")}
            </Typography>
        </CardContent>
        <CardActions>
            <a href="https://www.linkedin.com/in/vitalii-pavliuk/" target="_blank">
            <IconButton aria-label="linkedin">
                <LinkedIn />
            </IconButton>
            </a>
            <a href="https://instagram.com/rozlyvaiko" target="_blank">
                <IconButton aria-label="intagram">
                    <Instagram />
                </IconButton>
            </a>
            <a href="https://t.me/rozlyvaiko" target="_blank">
                <IconButton aria-label="telegram">
                    <Telegram />
                </IconButton>
            </a>
            <Button size="small" href="mailto:vitalii@vitalii.tech">{t("Contact me")}</Button>
            <Button size="small" href="#twin">{t("My twin")}</Button>
            <Button size="small" href="#book">{t("Book me")}</Button>
        </CardActions>
    </Card>
}

export default MyCard;
