import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {GPTQuery, GPTResponse} from "../types/GPTQuery";

export const gptApi = createApi({
  reducerPath: 'gptApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://api.vitalii.tech/api/v1/',
  }),
  endpoints: (builder) => ({
    postMessage: builder.mutation<GPTResponse, GPTQuery>({
        query: (body) => ({
                url: 'gpt/',
                method: 'POST',
                body,
            }),
        }),
    }),
});

export const { usePostMessageMutation } = gptApi;
