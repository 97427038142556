import {Avatar, Box, CircularProgress} from "@mui/material";
import React from "react";


interface MessageProps {
    role: string;
    message?: string;
    isLoading?: boolean;
}


const Message = (props: MessageProps) => {
    const justifyContent = props.role === "user" ? "flex-end" : "flex-start";

    const avatarSrc = props.role === "user" ? "/user.jpg" : props.role === "zlativ" ? "/zlativ.jpg" : "/twin.jpg";

    return <Box display="flex" flexDirection="row" justifyContent={justifyContent} sx={{margin: 2}}>
        <Box sx={{backgroundColor: "#FFF", padding: 2, maxWidth: {xs: "100%", md: "50%"}, float: "left", borderRadius: 5, display: "flex", gap: 2}}>
            <Avatar src={avatarSrc} />
            {props.message}
            {Boolean(props.isLoading) && <CircularProgress />}
        </Box>
    </Box>
}

export default Message;
