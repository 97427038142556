export interface StravaActivity {
    id: number;
    name: string;
    distance: number;
    type: string;
    start_date_local: string;
}

export const activityURL = (activity: StravaActivity) => {
    return `https://www.strava.com/activities/${activity.id}`;
}
