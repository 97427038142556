import React, {useEffect, useRef} from "react";
import {Avatar, Box, Button, Card, CardHeader, Input, Paper} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import Message from "./Message";
import {useDispatch, useSelector} from "../hooks";
import {RootState} from "../store";
import {usePollForMessages} from "../hooks/usePollForMessages";
import {appendMessage, GPTState, setIsLoading} from "../features/gpt/gptSlice";
import {useTranslation} from "react-i18next";

type ChatProps = {
    chatType: string;
    avatarSrc: string;
    title: string;
    subheader: string;
    sliceSelector: (state: RootState) => GPTState;
}

const Chat = ({ chatType, avatarSrc, title, subheader, sliceSelector }: ChatProps) => {
    const [message, setMessage] = React.useState("");
    const dispatch = useDispatch();

    const { messages, isLoading } = useSelector((state: RootState) => sliceSelector(state));

    const { t } = useTranslation();

    const initialMessage = {
        role: chatType === "zlativ" ? "zlativ": "assistant",
        content: chatType === 'vitalii' ? t("Hello! 👋 I'm Vitalii's Digital Twin, powered by AI. 🤖 Ready to chat about tech, triathlons, and the adventures of life! 🚀💻🏊‍♂️")
            : t("Доброго дня, як я можу Вам допомогти?"),
    }

    const allMessages = [initialMessage, ...messages];

    const { startPolling } = usePollForMessages(chatType);

    const messagesEndRef = useRef<HTMLDivElement>(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        if (messages.length > 1) {
            scrollToBottom()
        }
    }, [allMessages]);

    const onClick = () => {
        startPolling(message);
        setMessage("");
        dispatch(appendMessage({ role: "user", content: message }));
        dispatch(setIsLoading(true));
    }

    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            onClick();
        }
    }

    return <Card id={chatType} sx={{ margin: { xs: 1, md: 10 }, height: "calc(100vh - 224px)", display: "flex", flexDirection: "column" }}>
        <CardHeader
            avatar={<Avatar src={avatarSrc} />}
            title={title}
            subheader={subheader}
        />
        <Box display="flex" flexDirection="column" flexGrow={1}>
            <Paper elevation={3} sx={{ margin: 5, backgroundColor: "#EEE", flexGrow: 1 }}>
                <Box sx={{ overflow: "scroll", maxHeight: "calc(100vh - 443px)" }}>
                    {allMessages.map((message, index) => {
                        return <Message key={index} role={message.role} message={message.content} />
                    })}
                    {isLoading && <Message role="assistant" isLoading={true} />}
                    <div ref={messagesEndRef} />
                </Box>
            </Paper>
            <Box display="flex" justifyContent="flex-end" gap={5} sx={{ margin: 5, marginTop: 0 }}>
                <Input placeholder="Type your message here..." value={message} onChange={e => setMessage(e.target.value)} sx={{ flexGrow: 1 }} onKeyDown={onKeyDown} />
                <Button variant="contained" onClick={onClick} endIcon={<SendIcon />}>
                    Send
                </Button>
            </Box>
        </Box>
    </Card>;
}

export default Chat;