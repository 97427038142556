import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query'
import gptReducer from './features/gpt/gptSlice';
import zlativGPTReducer from './features/zlativGPT/zlativGPTSlice';
import { gptApi } from "./services/gpt";
import {instagramApi} from "./services/instagram";
import instagramReducer from "./features/instagram/instagramSlice";
import {stravaApi} from "./services/strava";
import stravaReducer from "./features/strava/stravaSlice";

export const store = configureStore({
    reducer: {
        gpt: gptReducer,
        zlativGPT: zlativGPTReducer,
        instagram: instagramReducer,
        strava: stravaReducer,
        [gptApi.reducerPath]: gptApi.reducer,
        [instagramApi.reducerPath]: instagramApi.reducer,
        [stravaApi.reducerPath]: stravaApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(gptApi.middleware).concat(instagramApi.middleware).concat(stravaApi.middleware),
})

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

export default store;
