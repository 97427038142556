import React from 'react';
import './App.css';
import {createTheme, ThemeProvider} from "@mui/material";
import {RouterProvider} from "react-router-dom";
import router from "./router";
import {Provider} from "react-redux";
import store from "./store";

const theme = createTheme();

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme} >
          <Provider store={store}>
              <RouterProvider router={router} />
          </Provider>
      </ThemeProvider>
    </div>
  );
}

export default App;
