import React from "react";
import Layout from "../layout/Layout";
import {Grid} from "@mui/material";
import Chat from "../components/Chat";
import DynamicHelmet from "../components/DynamicHelmet";

const ChatPage = () => {
    return (<Layout>
        <DynamicHelmet title="Chat - Vitalii Pavliuk's twin" description="Chat with Vitalii's digital twin" imageUrl="https://vitalii.tech/twin.jpg" url="https://vitalii.tech/twin"/>
        <Grid container sx={{height: "100vh", overflowY: "auto", backgroundColor: "#EEE"}}>
            <Grid item xs={12}>
                <Chat chatType="vitalii" avatarSrc="/twin.jpg" title="My Digital Twin" subheader="Online" sliceSelector={(state) => state.gpt} />
            </Grid>
        </Grid>
    </Layout>);
}

export default ChatPage;
