import React, {useEffect} from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import {useLazyGetLatestPostQuery} from "../services/instagram";
import {InstagramPost as InstagramPostData} from "../types/InstagramPost";
import {setLatestPost} from "../features/instagram/instagramSlice";
import {useSelector} from "../hooks";
import { useDispatch } from 'react-redux';
import {Avatar, CardActions, CardHeader} from "@mui/material";
import {useTranslation} from "react-i18next";
import Button from '@mui/material/Button';

const InstagramPost = () => {
    const [getLatestPost] = useLazyGetLatestPostQuery();

    const dispatch = useDispatch();

    const latestPost = useSelector((state) => state.instagram.latestPost);

    const [t, i18n] = useTranslation();

    useEffect(() => {
        (async () => {
            try {
                const response = await getLatestPost();
                const post = response.data as InstagramPostData;
                dispatch(setLatestPost(post));
            } catch (error) {
                console.error("Error fetching latest post:", error);
            }
        })();
    }, [getLatestPost, dispatch]);


    return latestPost && (
        <Card sx={{ margin: { xs: 1, md: 10} }}>
            <CardHeader
                avatar={
                    <Avatar src="/vitalii.png" />
                }
                title="Vitalii Pavliuk"
                subheader={new Date(latestPost.timestamp).toLocaleDateString(i18n.language)}

            />
            <CardMedia
                component={ latestPost.media_type === "VIDEO" ? "video" : "img" }
                image={latestPost.media_url}
                alt={`Instagram Post ${latestPost.id}`}
                autoPlay
            />
            <CardContent>
                <Typography gutterBottom component="div">
                    {latestPost.caption}
                </Typography>
            </CardContent>
            <CardActions>
                <Button size="small" href={latestPost.permalink} target="_blank" rel="noopener noreferrer">{t("View on Instagram")}</Button>
            </CardActions>
        </Card>
    );
};

export default InstagramPost;
