import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
    en: {
        translation: {}
    },
    uk: {
        translation: {
            "Sign Up": "Зареєструватись",
            "Name": "Імʼя",
            "Email": "Email",
            "Desired Language": "Мова, яку хочеш вивчати",
            "I agree with ": "Я погоджуюсь з ",
            "Terms&Conditions": "Договором про надання послуг",
            "Sign up to start learning programming:": "Зареєструйся, щоб почати навчатись програмуванню:",
            "Phone": "Телефон",
            "Hey! I'm Vitalii Pavliuk, a tech enthusiast and programming mentor from Ukraine. Dive into the digital realm with my AI-powered twin for a unique tech conversation, or book a session to elevate your coding skills with personalized guidance. Join me at the intersection of technology and education, where every interaction is a step towards mastering the digital world.":
                "Привіт! Я Віталій Павлюк, програміст, викладач і молодий науковець з України. На цьому сайті Ви можете поскілкуватись з моїм AI-двійником, або ж записатись до мене на консультацію, щоб підвищити свої навички програмування. Приєднуйтесь до мене на перетині технологій та освіти, де кожна дія це кроком назустріч цифровому світу.",
            "Hello! 👋 I'm Vitalii's Digital Twin, powered by AI. 🤖 Ready to chat about tech, triathlons, and the adventures of life! 🚀💻🏊‍♂️":
                "Привіт! 👋 Я ШІ-двійник Віталія, який працює на базі штучного інтелекту. 🤖 Готовий поговорити про технології, триатлон та пригоди життя! 🚀💻🏊‍♂️",
            "View on Instagram": "Переглянути в Instagram",
            "View on Strava": "Переглянути в Strava",
            "Contact me": "Зв'язатись зі мною",
            "My twin": "Мій двійник",
            "Book me": "Записатись до мене",
        }
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .init({
        resources,
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;