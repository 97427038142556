import React, {useEffect} from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {useSelector} from "../hooks";
import { useDispatch } from 'react-redux';
import {Avatar, CardActions, CardHeader} from "@mui/material";
import {useTranslation} from "react-i18next";
import Button from '@mui/material/Button';
import { useLazyGetLatestActivityQuery } from "../services/strava";
import {activityURL, StravaActivity as StravaActivityData} from "../types/StravaActivity";
import {setLatestActivity} from "../features/strava/stravaSlice";

const StravaActivity = () => {
    const [ getLatestActitity ] = useLazyGetLatestActivityQuery();

    const dispatch = useDispatch();

    const latestActivity = useSelector((state) => state.strava.latestActivity);

    const [t, i18n] = useTranslation();

    useEffect(() => {
        (async () => {
            try {
                const response = await getLatestActitity();
                const activity = response.data as StravaActivityData;
                dispatch(setLatestActivity(activity));
            } catch (error) {
                console.error("Error fetching latest activity:", error);
            }
        })();
    }, [getLatestActitity, dispatch]);


    return latestActivity && (
        <Card sx={{ margin: { xs: 1, md: 10} }}>
            <CardHeader
                avatar={
                    <Avatar src="/vitalii.png" />
                }
                title="Vitalii Pavliuk"
                subheader={new Date(latestActivity.start_date_local).toLocaleDateString(i18n.language)}

            />
            <CardContent>
                <Typography gutterBottom component="div">
                    {latestActivity.name} - {latestActivity.distance} m
                </Typography>
            </CardContent>
            <CardActions>
                <Button size="small" href={activityURL(latestActivity)} target="_blank" rel="noopener noreferrer">{t("View on Strava")}</Button>
            </CardActions>
        </Card>
    );
};

export default StravaActivity;
