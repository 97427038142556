import React from "react";
import {
    createBrowserRouter,
} from "react-router-dom";
import "./index.css";
import MainPage from "./pages/MainPage";
import ZlativPage from "./pages/ZlativPage";
import ChatPage from "./pages/ChatPage";

const router = createBrowserRouter([
    {
        path: "/",
        element: <MainPage/>,
    },
    {
        path: "/zlativ",
        element: <ZlativPage/>,
    },
    {
        path: "/chat",
        element: <ChatPage/>,
    },
]);

export default router;
