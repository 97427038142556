import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from "@reduxjs/toolkit";
import {StravaActivity} from "../../types/StravaActivity";

export const initialState = {
    latestActivity: null as StravaActivity | null,
}

export const stravaSlice = createSlice({
    name: 'strava',
    initialState,
    reducers: {
        setLatestActivity: (state, action: PayloadAction<StravaActivity>) => {
            state.latestActivity = action.payload;
        }
    },
});

export const { setLatestActivity } = stravaSlice.actions;

export default stravaSlice.reducer;
