import React from 'react';
import { Helmet } from 'react-helmet';

interface DynamicHelmetProps {
    title: string;
    description: string;
    imageUrl: string;
    url: string;
}

const DynamicHelmet = ({ title, description, imageUrl, url }: DynamicHelmetProps) => {
    return (
        <Helmet>
            {/* Basic tags */}
            <title>{title}</title>
            <meta name="description" content={description} />

            {/* OpenGraph tags */}
            <meta property="og:url" content={url} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={imageUrl} />

            {/* Twitter Card tags */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={imageUrl} />
        </Helmet>
    );
};

export default DynamicHelmet;
