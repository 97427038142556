import React from "react";
import Layout from "../layout/Layout";
import {Grid} from "@mui/material";
import Chat from "../components/Chat";
import DynamicHelmet from "../components/DynamicHelmet";

const MainPage = () => {
    return (<Layout>
        <DynamicHelmet title="Чат - віртуальний помічник доктора Златіва" description="Поспілкуйся з цифровим помічником Володимира Златіва" imageUrl="https://vitalii.tech/zlativ.jpg" url="https://vitalii.tech/zlativ"/>
        <Grid container sx={{height: "100vh", overflowY: "auto", backgroundColor: "#EEE"}}>
            <Grid item xs={12} md={6}>
                <Chat chatType="zlativ" avatarSrc="/zlativ.jpg" title="Dr. Zlativ Digital Twin" subheader="Online" sliceSelector={(state) => state.zlativGPT} />
            </Grid>
        </Grid>
    </Layout>);
}

export default MainPage;
