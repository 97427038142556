import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import InstagramPost from "../types/InstagramPost";

export const instagramApi = createApi({
  reducerPath: 'instagramApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://api.vitalii.tech/api/v1/instagram-posts/',
  }),
  endpoints: (builder) => ({
    getLatestPost: builder.query<InstagramPost, void>({
        query: () => 'last/',
        }),
    }),
});

export const { useLazyGetLatestPostQuery } = instagramApi;
