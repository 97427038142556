import {useCallback, useRef} from "react";
import { useDispatch } from "react-redux";
import { usePostMessageMutation } from "../services/gpt";
import {setIsLoading, setMessages} from "../features/gpt/gptSlice";

export const usePollForMessages = (model = "vitalii") => {
    const dispatch = useDispatch();
    const [postMessage] = usePostMessageMutation();
    const runIdRef = useRef<string | null>(null);
    const threadIdRef = useRef<string | null>(null);

    const startPolling = useCallback((message: string) => {
        let requestMessage: string | null = message;

        const timestamp = Date.now() / 1000;

        const intervalId = setInterval(async () => {
            const response = await postMessage({ message: requestMessage, thread_id: threadIdRef.current, run_id: runIdRef.current, model });

            requestMessage = null;

            if ("data" in response) {
                const newMessages = response.data.messages.filter((message) => message.timestamp > timestamp && message.content !== "");
                if (newMessages.length > 1) {
                    clearInterval(intervalId);
                    dispatch(setIsLoading(false));
                    dispatch(setMessages([...response.data.messages].reverse()));
                }
                threadIdRef.current = response.data.thread_id;
                runIdRef.current = response.data.run_id;
            }
        }, 2000); // Adjust the interval time as needed

        return () => clearInterval(intervalId);
    }, [postMessage, dispatch]);

    return { startPolling };
};
